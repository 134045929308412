<template>
  <div v-if="style" ref="lavContainer" :style="style" />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import lottie from 'lottie-web/build/player/lottie_light.min';

// Props
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  speed: {
    type: Number,
    default: 1,
  },
  width: {
    type: Number,
    default: -1,
  },
  height: {
    type: Number,
    default: -1,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
  loopDelayMin: {
    type: Number,
    default: 0,
  },
  loopDelayMax: {
    type: Number,
    default: 0,
  },
});

// Data
const rendererSettings = {
  scaleMode: 'centerCrop',
  clearCanvas: true,
  progressiveLoad: false,
  hideOnTransparent: true,
};

const anim = ref(null);
const style = ref(null);
const lavContainer = ref(null);

// Methods
const init = async () => {
  style.value = {
    width: props.width !== -1 ? `${props.width}px` : '100%',
    height: props.height !== -1 ? `${props.height}px` : '100%',
    overflow: 'hidden',
    margin: '0 auto',
  };

  if (anim.value) {
    anim.value.destroy();
  }

  anim.value = lottie.loadAnimation({
    container: lavContainer.value,
    renderer: 'svg',
    loop: props.loop,
    autoplay: props.autoPlay,
    animationData: props.data,
    rendererSettings,
  });

  anim.value.setSpeed(props.speed);

  if (props.loopDelayMin > 0) {
    anim.value.loop = false;
    anim.value.autoplay = false;
    executeLoop();
  }
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const executeLoop = () => {
  anim.value.play();
  setTimeout(() => {
    anim.value.stop();
    executeLoop();
  }, getRandomInt(props.loopDelayMin, props.loopDelayMax === 0 ? props.loopDelayMin : props.loopDelayMax));
};

// Mounted
onMounted(() => {
  init();
});

// Watcher
watch(() => props.path, () => {
  init();
}, { immediate: true });
</script>
